import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import HeaderImage from 'components/HeaderImage';

import { siteMetadata } from '../../gatsby-config';
import Layout from 'components/Layout';
import Meta from 'components/Meta';

const ContactPage = ({ location, data }) => {
  const header = get(data, 'header.childImageSharp.fluid');

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Not Found" />
      <HeaderImage image={header}>
        <h1>404 Not Found</h1>
      </HeaderImage>
      <p>Oops. There's no page here.</p>
      <p>
        You can use the links above to find your way back to places that exist.
      </p>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query Status404PageQuery {
    header: file(name: { eq: "laura-skinner-273192-unsplash" }) {
      childImageSharp {
        fluid(maxWidth: 800, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
